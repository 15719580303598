<div class="detail-page">

  <div class="container">

    <div class="backbar">
      <button class="btn btn-primary btn-arrow-left" appBackButton>Back</button>
    </div>

    <app-dine-content [showActivated]="true"/>

    @if (dineUrl()) {
    <div class="text-center">
      <a [href]="dineUrl()" target="_blank" class="btn btn-lg btn-primary px-4 rounded-pill btn-lg">Continue to Dine Club</a>
    </div>
    }

  </div>
</div>
