import {Component, computed} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BackButtonDirective} from 'src/app/shared/directives/back-button.directive';
import {DineContentComponent} from 'src/app/modules/dine-membership/dine-content/dine-content.component';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {HttpClient} from '@angular/common/http';

/**
 * This is the page explaining what Dine Club is, and includes links to direct the customer to the service.
 * On load, this calls out to ensure the enrollment is active.
 */

@Component({
  selector: 'app-dine-membership-page',
  standalone: true,
  imports: [CommonModule, BackButtonDirective, DineContentComponent],
  templateUrl: './dine-membership-page.component.html',
  styleUrls: ['./dine-membership-page.component.scss'],
})
export class DineMembershipPageComponent {

  /**
   * The Dine Club URL the button will post to
   */
  dineUrl = computed<string>(() => {
    const acct = this.store.selectSignal(SessionState.getAccount);
    const email = acct()?.user.email;

    if (!email) {
      return '';
    }

    // This includes the default password that Dine attaches to new accounts.  UKC-2702
    return `https://checkout.dineclub.co.uk/login?email=${email}&password=DINEDINEDINE&auto=true`;
  });

  constructor(private store: Store, private http: HttpClient) {
    this.http.get('/api/offers/dine-club/ensure-enrollment')
      .subscribe({
        next: () => console.debug('Dine enrollment confirmed'),
        error: e => console.error(e),
      });
  }
}
